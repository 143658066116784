// @ts-nocheck
import { cloneDeep } from 'lodash';
import { Auth, API } from 'aws-amplify';
import formatDate from '../lib/formatDate';
import { encodeNestedDateValues, transformNestedDateValues } from '../lib/transformNestedDates';
import { tryDeriveEndpointName } from 'vl-core/getApiEndpoint';

const ServiceApi = {};

const publicInit = {
  headers: {
    Authorization: ServiceApi.token,
    'Content-Type': 'application/json'
  },
  response: true,
  queryStringParameters: undefined,
  body: undefined
};

const logOut = async () => {
  console.log(Error('service api logOut'));
  try {
    await Auth.currentSession();
    await ServiceApi.put(`/users/logout/${ServiceApi.userGuid}`, {}, false);
    await Auth.signOut({ global: true });
  } catch (error) {
    console.debug('Session Expired at logout');
  }
  sessionStorage.clear();
  window.location.replace('#/account/login');
  window.location.reload();
};

const showLogoutAlert = (message = '') => {
  if (ServiceApi.SHOW_LOGOUT_ALERT) {
    // eslint-disable-next-line no-alert
    alert(message);
  }
}

ServiceApi.getCurrentUser = async () => {
  const localInit = cloneDeep(publicInit);
  const currentSession = await Auth.currentSession();
  ServiceApi.userGuid = currentSession.getIdToken().payload.sub;
  ServiceApi.token = `Bearer ${currentSession.getIdToken().getJwtToken()}`;
  publicInit.headers.Authorization = ServiceApi.token;
  localInit.headers.Authorization = ServiceApi.token;
  return API.get(tryDeriveEndpointName(), `/users/user/${ServiceApi.userGuid}`, localInit);
};

ServiceApi.refreshToken = async (token) => {
  const localInit = cloneDeep(publicInit);
  if (!token) {
    const currentSession = await Auth.currentSession();
    token = `Bearer ${currentSession.getIdToken().getJwtToken()}`;
  }
  ServiceApi.token = token;
  publicInit.headers.Authorization = ServiceApi.token;
  localInit.headers.Authorization = ServiceApi.token;
};

ServiceApi.get = async (url, input, token = true) => {
  if (!window.checkExpireSession()) {
    await logOut();
    showLogoutAlert(`You were logged out due to session expiry on a GET request to ${url}`)
  }
  if (token) {
    await ServiceApi.refreshToken();
  }
  const localInit = cloneDeep(publicInit);
  if (input) {
    localInit.queryStringParameters = encodeNestedDateValues(input.params);
  }
  return new Promise((resolutionFunc, rejectionFunc) => {
    API.get(tryDeriveEndpointName(), url, localInit)
      .then((res) => transformNestedDateValues(res))
      .then((res) => {
        resolutionFunc(res);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          logOut();
          showLogoutAlert(`Unauthorised to access: ${url}. Please contact support with a screenshot of this page.`);
        }
        rejectionFunc(err);
      });
  });
};

function formatDates(obj) {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      return [
        key,
        value instanceof Date ? formatDate(value, 'Date & Time (short ISO 1806) - 2022-03-02T17:19Z') : value
      ];
    })
  );
}

ServiceApi.post = async (url, input, token = true) => {
  const localInit = cloneDeep(publicInit);

  if (!window.checkExpireSession()) {
    await logOut();
    showLogoutAlert(`You were logged out due to session expiry on a POST request to ${url}`);
  }

  if (token) {
    await ServiceApi.refreshToken();
  } else {
    localInit.noAuth = true;
  }

  if (input) {
    localInit.body = formatDates(input);
  }

  return new Promise((resolutionFunc, rejectionFunc) => {
    API.post(tryDeriveEndpointName(), url, localInit)
      .then((res) => {
        resolutionFunc(res);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          logOut();
          showLogoutAlert(`Unauthorised to access: ${url}. Please contact support with a screenshot of this page.`);
        }
        rejectionFunc(err);
      });
  });
};

ServiceApi.put = async (url, input, logout = true) => {
  if (!window.checkExpireSession()) {
    if (logout) {
      await logOut();
      showLogoutAlert(`You were logged out due to session expiry on a PUT request to ${url}`);
    }
    return;
  }
  await ServiceApi.refreshToken();
  const localInit = cloneDeep(publicInit);

  if (input) {
    localInit.body = formatDates(input);
  }
  return new Promise((resolutionFunc, rejectionFunc) => {
    API.put(tryDeriveEndpointName(), url, localInit)
      .then((res) => {
        resolutionFunc(res);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          if (logout) {
            logOut();
            showLogoutAlert(`Unauthorised to access: ${url}. Please contact support with a screenshot of this page.`);
          }
        }
        rejectionFunc(err);
      });
  });
};

export default ServiceApi;

export { encodeNestedDateValues, transformNestedDateValues, showLogoutAlert };
