// import { URLSearchParams } from 'url';
import { z } from 'zod';
import * as actionTypes from './actionTypes';
import { fetchData } from './action';

/* imgDetailsMetaRes */
export const imgDetailsMetaRes = (clinician_user_guid, appointment_id) => {
  const requestOption = {
    params: `meta/invreq/${clinician_user_guid}/${appointment_id}`
  };
  return fetchData(requestOption);
};

export const getPatientDetails = (clinician_user_guid, patient_user_guid) => {
  const requestOption = {
    params: `referrers/patients/${clinician_user_guid}?pat_guid=${patient_user_guid}`
  };
  return fetchData(requestOption);
};

export const getClinicianDetails = (clinician_user_guid: string, patient_user_guid: string) => {
  const requestOption = {
    params: `users/user/${clinician_user_guid}?pat_guid=${patient_user_guid}`
  };
  return fetchData(requestOption);
};

export const getSavedPDF = (appointment_id, ireq_id?) => {
  const requestOption = {
    params: `service-request?appt_id=${appointment_id}${ireq_id ? `&ireq_id=${ireq_id}` : ''}`,
    actionType: actionTypes.GET_PDF_FORM_SUCCESS,
    errorType: actionTypes.GET_PDF_FORM_FAIL
  };
  return fetchData(requestOption);
};

export const getClinicianSignature = () => {
  const requestOption = {
    params: 'signature'
  };
  return fetchData(requestOption);
};

export const postCurrentPDF = (appointment_id, ireq_id) => {
  const requestOption = {
    params: `service-request?appt_id=${appointment_id}&ireq_id=${ireq_id}`,
    method: 'post'
  };
  return fetchData(requestOption);
};

export const getProviders = (
  clinician_user_guid,
  imagingType,
  postcode,
  appointment_id,
  tag_filter: boolean = false
) => {
  const requestOption = {
    params: `providers/imgprovs/${clinician_user_guid}?page=1&rows=18&type=${imagingType}&geo_postcode=${postcode}&appt_id=${appointment_id}&tag_filter=${tag_filter}`
  };
  return fetchData(requestOption);
};

export const postPDFState = (appointment_id, ireq_id, postBody) => {
  const options = {
    params: `service-request?appt_id=${appointment_id}&ireq_id=${ireq_id}`,
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const sendAudioCallStarted = (appt_id: number, tel_no: string) => {
  const options = {
    params: 'clinicians/appt/calls/audio/APPUSER',
    method: 'post',
    postBody: {
      appt_id,
      tel_no
    }
  };
  return fetchData(options);
};

// Here
export const deleteInvestigationRequest = (appointmentId, investigationRequestId) => {
  const options = {
    params: 'clinicians/delete/invreq',
    method: 'post',
    postBody: {
      appt_id: appointmentId,
      ireq_id: investigationRequestId
    }
  };
  return fetchData(options);
};

interface CreateInvestigationRequestBody {
  user_guid: string;
  appt_id: number;
  urgency_code: string;
  img_type_code: string;
  contrast_scan?: boolean;
  body_region_code?: string;
  body_side?: string;
  reason?: string;
  history?: string;
}

export const createInvestigationRequest = (postBody: CreateInvestigationRequestBody) => {
  const options = {
    params: 'clinicians/invreq',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

interface UpdateInvestigationRequestBody {
  user_guid: string;
  ireq_id: number;
  appt_id: number;
  urgency_code: string;
  img_type_code: string;
  contrast_scan?: boolean;
  body_region_code?: string;
  body_side?: string;
  reason?: string;
  history?: string;
}

export const updateInvestigationRequest = (postBody: UpdateInvestigationRequestBody) => {
  const options = {
    params: 'clinicians/invreq',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const postProviderData = (postBody) => {
  const options = {
    params: 'clinicians/invreq/provider',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const getDrugsByName = (clinician_user_guid, appointment_id, drug_name) => {
  const options = {
    params: `medication/vtm/${clinician_user_guid}?appt_id=${appointment_id}&vtm_name=${drug_name}`
  };
  return fetchData(options);
};

export const getDrugForms = (clinician_user_guid: string, appointment_id: number, vtm_id: string) => {
  const options = {
    params: `medication/vmp/${clinician_user_guid}?appt_id=${appointment_id}&vtm_id=${vtm_id}`
  };
  return fetchData(
    options,
    z.object({
      vmp: z.array(
        z.object({
          vtm_id: z.string(),
          vtm_name: z.string(),
          vmp_id: z.string(),
          vmp_name: z.string(),
          bnf_url: z.string()
        })
      )
    })
  );
};

export const createSavedMedication = (postBody) => {
  const options = {
    params: 'medication/create',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const disableSavedMedication = (postBody) => {
  const options = {
    params: 'medication/disable',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const setAppointmentWillPrescribe = (appt_id: number, prescribe: boolean) => {
  const options = {
    params: `medication/appt/prescribe/APPUSER?appt_id=${appt_id}&prescribe=${prescribe ? 1 : 0}`,
    method: 'post',
    postBody: {}
  };
  return fetchData(options);
};

// bottom

// Satisfaction Response
export const clinicianSatisfactionScore = () => {
  const options = {
    params: 'clinicians/satis/APPUSER',
    actionType: actionTypes.CLINICIANS_SATISFACTION_SCORE_SUCCESS,
    errorType: actionTypes.CLINICIANS_SATISFACTION_SCORE_FAIL
  };
  return fetchData(options);
};

// Timeline appointment history
export const clinicianAppointment = (params, isCompleted) => {
  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  const requestUrl = `booking/appointments/APPUSER?${queryString}&completed=${isCompleted}`;
  const isCompleteTrue = {
    params: requestUrl,
    actionType: actionTypes.CLINICIANS_APPOINTMENT_COMPLETE_SUCCESS,
    errorType: actionTypes.CLINICIANS_APPOINTMENT_COMPLETE_FAIL
  };
  const isCompleteFalse = {
    params: requestUrl,
    actionType: actionTypes.CLINICIANS_APPOINTMENT_ACTIVE_SUCCESS,
    errorType: actionTypes.CLINICIANS_APPOINTMENT_ACTIVE_FAIL
  };
  const parameters = isCompleted ? isCompleteTrue : isCompleteFalse;
  return fetchData(parameters);
};

export const getGPRecords = (appt_id, page) => {
  const options = {
    params: `gpc?appt_id=${appt_id}`,
    method: 'post',
    postBody: {
      appt_id,
      page
    }
  };
  return fetchData(options);
};

export const getErsReferralAttachments = (appt_id, ubrn) => {
  const options = {
    params: `ers/referral/attachments?user_guid=APPUSER&appt_id=${appt_id}&ubrn=${ubrn}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getErsReferralDocument = (appt_id, ubrn, attachment_id) => {
  const options = {
    params: `ers/referral/document?user_guid=APPUSER&appt_id=${appt_id}&ubrn=${ubrn}&attachment_id=${attachment_id}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getErsReferralInformation = (appt_id, ubrn) => {
  // seen user_guid replaced by APPUSER elsewhere

  const options = {
    params: `ers/referral/information?user_guid=APPUSER&appt_id=${appt_id}&ubrn=${ubrn}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getErsPatientDetails = (appt_id, ubrn) => {
  const options = {
    params: `ers/referral/patient?user_guid=APPUSER&appt_id=${appt_id}&ubrn=${ubrn}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getUBRNsFromCaseNotes = (case_id, patient_guid, appt_id) => {
  const options = {
    params: `notes/patient/APPUSER?case_id=${case_id}&patient_guid=${patient_guid}&appt_id=${appt_id}&category=ERS&rows=999`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getCaseNotes = (case_id, patient_guid, appt_id) => {
  const options = {
    params: `notes/patient/APPUSER?case_id=${case_id}&patient_guid=${patient_guid}&appt_id=${appt_id}&rows=999`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getAppointmentDetails = (current_appt_id: number) => {
  const options = {
    params: `clinicians/appt/details/APPUSER?appt_id=${current_appt_id}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getSharedReferrerNotes = (patient_guid, case_id = '') => {
  const options = {
    params: `notes/patient/APPUSER?patient_guid=${patient_guid}&rows=999${case_id ? `&case_id=${case_id}` : ''}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const clinicalValidation = (postBody) => {
  const options = {
    params: 'surveys/covid19',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const submitCaseReview = (postBody) => {
  const options = {
    params: 'booking/case/review',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const clinicianMeta = () => {
  const options = {
    params: 'meta/clinicians/APPUSER'
  };
  return fetchData(options);
};

export const specialityAreas = (speccat) => {
  const options = {
    params: `admin/specareas/APPUSER?rows=999&sort=area&speccat=${speccat}`
  };
  return fetchData(options);
};

export const clinicianViewCaseNotes = (id) => {
  const options = {
    params: `notes/patient/APPUSER/?case_id=${id}&sort=date_added&order=desc`
  };
  return fetchData(options);
};

export const clinicianApointmentSuggestions = (postBody) => {
  const options = {
    params: 'clinicians/appt/suggest',
    method: 'POST',
    postBody
  };
  return fetchData(options);
};

export const clinicianAddCaseNotes = (postBody) => {
  const options = {
    params: 'notes/patient',
    method: 'POST',
    postBody
  };
  return fetchData(options);
};

export const clinicianNotesPost = (postBody) => {
  const options = {
    params: 'notes/appt/',
    postBody,
    method: 'post'
  };
  return fetchData(options);
};

export const clinicianFlagsPost = (postBody) => {
  const options = {
    params: 'cases/flags',
    postBody,
    method: 'POST'
  };
  return fetchData(options);
};

export const clinicianInvReqByApptId = (page, appt_id) => {
  const options = {
    params: `clinicians/invreq/APPUSER?page=${page}&appt_id=${appt_id}`
  };
  return fetchData(options);
};
export const clinicianInvReqByCaseId = (page, case_id) => {
  const options = {
    params: `clinicians/invreq/APPUSER?page=${page}&case_id=${case_id}`
  };
  return fetchData(options);
};

export const clinicianInvReqImgDetails = () => {
  const options = {
    params: 'meta/invreq/APPUSER'
  };
  return fetchData(options);
};

export const clinicianInvReqSurvey = (survey_id, appt_id) => {
  const options = {
    params: `clinicians/invreq/survey/APPUSER?hier_code=INVREQ${survey_id}&appt_id=${appt_id}`
  };
  return fetchData(options);
};

export const getClinician = (clin_guid: string) => {
  const options = {
    params: `clinicians/clinicians/${clin_guid}?clin_guid=${clin_guid}`
  };
  return fetchData(options);
};

export const clinicianInvReqSurveyPost = (user_guid, appt_id, postBody) => {
  const options = {
    params: 'clinicians/invreq/survey',
    postBody: {
      ...postBody,
      user_guid,
      appt_id
    },
    method: 'post'
  };
  return fetchData(options);
};

export const clinicianInvReqProvider = (postcode, type) => {
  const options = {
    params: `providers/imgprovs/APPUSER?rows=99&geo_postcode=${postcode}&type=${type}`
  };
  return fetchData(options);
};

export const clinicianInvReqProviderPost = (user_guid, appt_id, postBody) => {
  const options = {
    params: 'clinicians/invreq/provider',
    postBody: {
      ...postBody,
      user_guid,
      appt_id
    },
    method: 'POST'
  };
  return fetchData(options);
};

export const clinicianDiagnosisAndOutcomeICD10 = (search) => {
  const options = {
    noUrl: false,
    params: `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&authenticity_token=&terms=${search}`
  };
  return fetchData(options);
};

export const clinicianDiagnosisAndOutcomeAppointmentOutcomeSave = (request) => {
  const options = {
    postBody: request,
    method: 'POST',
    params: 'clinicians/appt/outcome',
    actionType: actionTypes.CLINICIANS_SAVE_APPOINTMENT_OUTCOME_SUCCESS,
    errorType: actionTypes.CLINICIANS_SAVE_APPOINTMENT_OUTCOME_FAIL
  };
  return fetchData(options);
};

export const clinicianDiagnosisAndOutcomeICD10Save = (request) => {
  const options = {
    postBody: request,
    method: 'POST',
    params: 'clinicians/appt/icd'
  };
  return fetchData(options);
};

export const clinicianDiagnosisAndOutcomeICD10Delete = (appointmnet_id, clinician_user_guid) => {
  const options = {
    postBody: { user_guid: clinician_user_guid, appt_id: appointmnet_id },
    method: 'POST',
    params: 'clinicians/appt/delete/icd'
  };
  return fetchData(options);
};

export const clinicianDiagnosisAndOutcomePifuGet = (user_guid, appt_id) => {
  const options = {
    method: 'GET',
    params: `clinicians/appt/pifu/${user_guid}/?appt_id=${appt_id}`
  };
  return fetchData(options);
};

export const clinicianDiagnosisAndOutcomePifuSave = (request) => {
  const options = {
    postBody: request,
    method: 'POST',
    params: 'clinicians/appt/pifu'
  };
  return fetchData(options);
};

export const clinicianDOFactsheets = (search, appt_id) => {
  const options = {
    params: `users/factsheets/APPUSER?rows=999&name=${search !== undefined ? search : ''}&appt_id=${appt_id}`
  };
  return fetchData(options);
};

export const clinicianGeneralGet = (appt_id) => {
  const options = {
    params: `meta/suggest/APPUSER/${appt_id}`
  };
  return fetchData(options);
};
export const clinicianDOFactsheetSave = (request) => {
  const options = {
    method: 'POST',
    postBody: request,
    params: 'clinicians/appt/factsheet'
  };
  return fetchData(options);
};

export const appointmentTasks = (appt_id: string, restrict_mode?: string) => {
  const requestOption = {
    params: `tasks/APPUSER?appt_id=${appt_id}${restrict_mode ? `&restrict_mode=${restrict_mode}` : ''}`
  };

  return fetchData(requestOption);
};

/* clinician completed  tasks */
export const clinicianAllTasks = (params, isActive) => {
  const parameter =
    params !== undefined
      ? Object.keys(params)
          .map((key) => (params[key] !== undefined ? `${key}=${params[key]}` : ''))
          .join('&')
      : null;

  const isActiveTrue = {
    actionType: actionTypes.CLINICIANS_OPEN_TASKS_SUCCESS,
    errorType: actionTypes.CLINICIANS_OPEN_TASKS_FAIL
  };
  const isActiveFalse = {
    actionType: actionTypes.CLINICIANS_COMPLETED_TASKS_SUCCESS,
    errorType: actionTypes.CLINICIANS_COMPLETED_TASKS_FAIL
  };

  const requestOption = {
    params: `tasks/APPUSER?sort=due_date&order=asc&active=${isActive}${parameter ? `&${parameter}` : ''}`,
    ...(isActive ? isActiveTrue : isActiveFalse)
  };
  return fetchData(requestOption);
};

/* getPreAssetments */
export const clinicianGetPreAssetments = () => {
  const requestOption = {
    params: 'users/preass/APPUSER?hier_code=PREASS1'
  };
  return fetchData(requestOption);
};

/* getPreAssetments Question */
export const clinicianPreAssetmentsQuestion = (request) => {
  const requestOption = {
    params: 'users/preass',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.CLINICIANS_GENERAL_QUESTIONS_SUCCESS,
    errorType: actionTypes.CLINICIANS_GENERAL_QUESTIONS_FAIL
  };
  return fetchData(requestOption);
};

export const uploadDocsDatabase = (request) => {
  const options = {
    params: 'files/appt/files/upload',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.UPLOAD_DOCS_DATABASE_SUCCESS,
    errorType: actionTypes.UPLOAD_DOCS_DATABASE_FAIL
  };
  return fetchData(options);
};

export const uploadCaseDocsDatabase = (request) => {
  const options = {
    params: 'files/case/files/upload',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.UPLOAD_DOCS_DATABASE_SUCCESS,
    errorType: actionTypes.UPLOAD_DOCS_DATABASE_FAIL
  };
  return fetchData(options);
};

export const uploadDocs = (request) => {
  const options = {
    params: 'file',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.UPLOAD_DOCS_SUCCESS,
    errorType: actionTypes.UPLOAD_DOCS_FAIL
  };
  return fetchData(options);
};

export const publishToPatient = (request: {
  /** The calling user’s GUID */
  user_guid: string;
  /** `document_id` from GET `clinicians/appt/letter_files` for a file with `document_type` `Uploaded` */
  file_id: number;
  appt_id?: number;
}) => {
  const options = {
    params: 'files/appt/files/publish_to_patient',
    method: 'POST',
    postBody: request
  };
  return fetchData(options);
};

export const getDocs = (request) => {
  const parameter = Object.keys(request)
    .map((key) => `${key}=${request[key]}`)
    .join('&');
  const options = {
    params: `file?${parameter}`
  };
  return fetchData(options);
};

export const getLetterCodes = (appt_id) => {
  const options = {
    params: `clinicians/LetterCodes/APPUSER?appt_id=${appt_id}`
  };
  return fetchData(options);
};

/* Data Protection */
export const dataProtection = (appId) => {
  const options = {
    params: `clinicians/appt/sec/APPUSER?appt_id=${appId}&date_check=false`,
    actionType: actionTypes.DATA_PROTECTION_SUCCESS,
    errorType: actionTypes.DATA_PROTECTION_FAIL
  };
  return fetchData(options);
};

export const getRehab = (user_guid, case_id) => {
  const options = {
    params: `clinicians/case/rehab/APPUSER?user_guid=${user_guid}&case_id=${case_id}`
  };
  return fetchData(options);
};

export const postRehab = (user_guid, case_id, rehab_link) => {
  const options = {
    params: 'clinicians/case/rehab',
    method: 'post',
    postBody: {
      user_guid,
      case_id,
      rehab_link
    }
  };
  return fetchData(options);
};

export const postDataProtection = (request) => {
  const requestOption = {
    params: 'clinicians/appt/sec',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.POST_DATA_PROTECTION_SUCCESS,
    errorType: actionTypes.POST_DATA_PROTECTION_FAIL
  };
  return fetchData(requestOption);
};

export const getLamdaPDF = (userId, appId) => {
  const requestOption = {
    params: `pdfgen?appointmentID=${appId}&userGUID=${userId}`,
    actionType: actionTypes.LAMDA_PDF_SUCCESS,
    errorType: actionTypes.LAMDA_PDF_FAIL
  };
  return fetchData(requestOption);
};

export const getVoiceCallToken = () => {
  const requestOption = {
    params: 'voicetokengen',
    actionType: actionTypes.VOICE_TOKEN_SUCCESS,
    errorType: actionTypes.VOICE_TOKEN_FAIL
  };
  return fetchData(requestOption);
};

export const saveLamdaPDF = (userId, appId, patientGUID, request) => {
  const requestOption = {
    method: 'POST',
    params: `pdfgen?appointmentID=${appId}&userGUID=${userId}&patientUserGUID=${patientGUID}`,
    postBody: request
  };
  return fetchData(requestOption);
};

export const getPatientAssessments = (request) => {
  const queryString = Object.keys(request)
    .map((key) => `${key}=${request[key]}`)
    .join('&');
  const requestOption = {
    params: `surveys/surveyjs/APPUSER?${queryString}`,
    actionType: actionTypes.PATIENT_ASSESSMENTS_SUCCESS,
    errorType: actionTypes.PATIENT_ASSESSMENTS_FAIL
  };
  return fetchData(requestOption);
};

export const getLetterFiles = (request) => {
  const queryString = Object.keys(request)
    .map((key) => `${key}=${request[key]}`)
    .join('&');
  const requestOption = {
    params: `clinicians/appt/letter_files/APPUSER?${queryString}`,
    actionType: actionTypes.LETTER_FILES_SUCCESS,
    errorType: actionTypes.LETTER_FILES_FAIL
  };
  return fetchData(requestOption);
};

export const deleteLetterFile = (request) => {
  const options = {
    params: 'files/appt/files/remove',
    method: 'POST',
    postBody: request
  };
  return fetchData(options);
};

export const taskAssigneeList = () => {
  const options = {
    params: 'tasks/assignee/APPUSER'
  };
  return fetchData(options);
};

export const taskMetaData = () => {
  const options = {
    params: 'meta/tasks/APPUSER'
  };
  return fetchData(options);
};

export const task2MetaData = () => {
  const options = {
    params: 'meta/tasks2/APPUSER'
  };
  return fetchData(options);
};

export const taskPatientLookup = (search) => {
  const options = {
    params: `referrers/patients/APPUSER?page=1&rows=100&order=desc&fuzzy=${search}`
  };
  return fetchData(options);
};

export const taskCases = (patient, isActive) => {
  const options = {
    params: `cases/APPUSER?page=1&rows=10&active=${isActive}&patient=${patient}`,
    actionType: actionTypes.CREATE_TASK_SUCCESS,
    errorType: actionTypes.CREATE_TASK_FAIL
  };
  return fetchData(options);
};

export const taskCreate = (request) => {
  const options = {
    params: 'tasks/create',
    method: 'PUT',
    postBody: request
  };
  return fetchData(options);
};

export const caseTriage = (request) => {
  const options = {
    params: 'cases/triage',
    method: 'POST',
    postBody: request
  };
  return fetchData(options);
};

export const completeSession = (request) => {
  const options = {
    postBody: request,
    method: 'POST',
    params: 'clinicians/appt/complete',
    errorType: actionTypes.ACTION_FAIL
  };
  return fetchData(options);
};
export const getRefClinicians = (spec_cat, geo_postcode, subSpeciality, caseId) => {
  const options = {
    params: `clinicians/refclins/APPUSER?spec_cat=${spec_cat}&geo_postcode=${geo_postcode}${
      subSpeciality ? `&specarea=${subSpeciality}` : ''
    }${caseId ? `&case_id=${caseId}` : ''}`
  };
  return fetchData(options);
};
export const getReferral = (appt_id) => {
  const options = {
    params: `referrers/case/refer/APPUSER/?appt_id=${appt_id}`
  };
  return fetchData(options);
};
export const getSpecialities = (see_all = 0) => {
  const options = {
    params: `admin/speccats/APPUSER/?rows=999&see_all=${see_all}`
  };
  return fetchData(options);
};
export const getSubSpecialities = (spec_cat, see_all = 0) => {
  const options = {
    params: `admin/specareas/APPUSER/?rows=999&sort=area&speccat=${spec_cat}&see_all=${see_all}`
  };
  return fetchData(options);
};
export const postReferral = (postBody) => {
  const options = {
    params: 'referrers/case/refer',
    method: 'post',
    postBody
  };
  return fetchData(options);
};
export const getAllPDFData = (id) => {
  const options = {
    params: `clinicians/appt/letter/APPUSER?appt_id=${id}`
  };
  return fetchData(options);
};

export const appointmentMetaData = (appt_id) => {
  const options = {
    params: `meta/appointments/APPUSER?appt_id=${appt_id}`
  };
  return fetchData(options);
};

export const selectedCase = (patientID) => {
  const options = {
    params: `cases/APPUSER?page=1&rows=100&patient=${patientID}&first_incomplete=true`
  };
  return fetchData(options);
};

export const getFile = (id, file) => {
  const options = {
    params: `file?user_guid=${id}&filename=${file}`
  };
  return fetchData(options);
};

export const getReport = (report_id, dataset_id) => {
  const options = {
    params: `powerbi?report_id=${report_id}&dataset_id=${dataset_id}`
  };
  return fetchData(options);
};

export const getReportGroups = () =>
  fetchData({
    params: 'admin/powerbi/groups/APPUSER'
  });

export const clinicianCases = (params) => {
  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  const requestUrl = `cases/APPUSER?${queryString}&204=true`;
  let parameters = {};
  if (params && params.new_case) {
    parameters = {
      params: requestUrl,
      actionType: actionTypes.CLINICIANS_CASE_NEW_SUCCESS,
      errorType: actionTypes.CLINICIANS_CASE_NEW_FAIL
    };
  } else if (params && params.active) {
    parameters = {
      params: requestUrl,
      actionType: actionTypes.CLINICIANS_CASE_ACTIVE_SUCCESS,
      errorType: actionTypes.CLINICIANS_CASE_ACTIVE_FAIL
    };
  } else {
    parameters = {
      params: requestUrl,
      actionType: actionTypes.CLINICIANS_CASE_CLOSED_SUCCESS,
      errorType: actionTypes.CLINICIANS_CASE_CLOSED_FAIL
    };
  }
  return fetchData(parameters);
};

export const clinicianCasesQueue = (params) => {
  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  const requestUrl = `cases/queues/APPUSER?${queryString}&204=true`;
  let parameters = {};
  parameters = {
    params: requestUrl,
    actionType: actionTypes.CLINICIANS_CASE_NEW_SUCCESS,
    errorType: actionTypes.CLINICIANS_CASE_NEW_FAIL
  };
  return fetchData(parameters);
};

export const getCase = (caseId: number) => {
  const options = {
    params: `cases/APPUSER?case=${caseId}`
  };
  return fetchData(options);
};

export const assignCase = (postBody) => {
  const options = {
    params: 'booking/case/review',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const prescribeSignaturePharmacy = (appt_id) => {
  return fetchData({
    method: 'put',
    params: 'sigpharm/prescribe',
    postBody: { appt_id }
  });
};

export const getSignaturePharmacyCredentials = () => {
  return fetchData({ params: 'sigpharm' });
};

export const setSignaturePharmacyCredentials = ({
  clin_guid: user_guid,
  ...postBody
}: {
  clin_guid: string;
  prescriber_id: string;
  secure_pin: string;
}) => {
  return fetchData({
    method: 'post',
    params: 'clinicians/sigpharm',
    postBody: { user_guid, ...postBody }
  });
};

export const updateUrgency = (user_guid, case_id, urg_code) => {
  const options = {
    method: 'POST',
    params: 'cases/urgency',
    postBody: { user_guid, case_id, urg_code }
  };
  return fetchData(options);
};

export const getPatientList = (clinician_user_guid, postBody) => {
  const searchParams = new URLSearchParams({
    ...postBody
  });
  const requestOption = {
    method: 'GET',
    params: `referrers/patients/${clinician_user_guid}?${searchParams.toString()}`
  };
  return fetchData(requestOption);
};

export const getClientList = (params) => {
  const searchParams = new URLSearchParams({
    ...params
  });
  const requestOption = {
    method: 'GET',
    params: `admin/companies/APPUSER/?${searchParams.toString()}`
  };
  return fetchData(requestOption);
};

export const getCaseEligibility = (case_id) => {
  const requestOption = {
    method: 'GET',
    params: `cases/eligibility/APPUSER?case_id=${case_id}`
  };
  return fetchData(requestOption);
};
