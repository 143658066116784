import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import * as actions from '@actions';
import { useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useIsRestricted from '@hooks/useIsRestricted';
import * as actionTypes from '@actionTypes';

interface ActionReturnType {
  payload: ActionPayload[];
  type: string;
  errorType: string;
}

interface ActionPayload {
  appointment_completed: boolean;
  appointment_id: number;
  appointment_type: string;
  call_type_code: string;
  condition: string;
  end: string;
  entry_type: string;
  lucy_id: string;
  patient: string;
  slot_duration: number;
  start: string;
}

interface AppointmentEvent {
  id: number;
  patient: string;
  appointment_type: string;
  condition: string;
  lucy_id: string;
  start: Date;
  end: Date;
  duration: number;
  completed: boolean;
  call_type_code: string;
  appointment_id: number;
  entry_type: string;
}

interface PayloadEventsWithDuration {
  slot_duration: number | null;
  appointments: ActionPayload[];
}

interface UseClinicianDiaryParams {
  invalidateOnly?: boolean;
}

interface UseClinicianDiary {
  eventList?: AppointmentEvent[];
  invalidateCalendarEvents: () => void;
  slotDuration?: number | null;
  weekOffset: number;
  setWeekOffset: Dispatch<SetStateAction<number>>;
  isNextDateOutOfRange: (date: string, offset: number) => boolean;
}

const getAppointmentsAndDurationFromPayload = (payload: ActionPayload[]): PayloadEventsWithDuration => {
  return payload.reduce(
    (acc: PayloadEventsWithDuration, event: ActionPayload) => {
      if (event.entry_type === 'duration') {
        acc.slot_duration = event?.slot_duration || null;
        return acc;
      }

      acc.appointments.push(event);
      return acc;
    },
    { slot_duration: null, appointments: [] }
  );
};

/**
 * Hook to manage clinician diary events and related functionalities. Primarily used by Calendar component.
 *
 * @returns {Object} An object containing:
 *   - eventList: An array of appointment event objects representing clinician diary events.
 *   - invalidateCalendarEvents: A function to invalidate the calendar events data.
 *   - slotDuration: The duration of slots for appointments.
 *   - weekOffset: The offset of the current week.
 *   - setWeekOffset: A function to set the week offset.
 *   - isNextDateOutOfRange: A function to check if the next date is out of range of selected week.
 *
 * @param {Object}
 *   - invalidateOnly: Optional parameter to indicate if only data invalidation is required,
 *     primarily used outside the Calendar component.
 */

export default function useClinicianDiary({ invalidateOnly }: UseClinicianDiaryParams = {}): UseClinicianDiary {
  const dispatch = useDispatch();
  const isRestricted = useIsRestricted();
  const queryClient = useQueryClient();
  const [eventList, setEventList] = useState<AppointmentEvent[]>([]);
  const [slotDuration, setSlotDuration] = useState<number | null>(null);
  const [weekOffset, setWeekOffset] = useState<number>(0);
  const [firstDayOfCurrentWeek, setFirstDayOfCurrentWeek] = useState<Date>();
  const [lastDayOfCurrentWeek, setLastDayOfCurrentWeek] = useState<Date>();

  const fetchData = (firstDayOfCurrentWeek?: Date, lastDayOfCurrentWeek?: Date, weekOffset?: number) => {
    return dispatch(
      actions.getUserAppointmentWithBreaks('USER_DIARY', {
        rows: 9999,
        cancelled: 'false',
        sort: 'datetime',
        date_start: firstDayOfCurrentWeek?.toISOString().split('T')[0],
        date_end: lastDayOfCurrentWeek?.toISOString().split('T')[0],
        week_offset: weekOffset
      })
    );
  };

  const getFirstDayOfWeek = (date: Date) => {
    const day = date.getDay();
    return date.getDate() - day + (day === 0 ? -6 : 1);
  };

  const isNextDateOutOfRange = useCallback(
    (date: string, offset: number) => {
      const selectedWeekFirstDay = new Date(firstDayOfCurrentWeek as Date);
      selectedWeekFirstDay.setDate(selectedWeekFirstDay.getDate() + 7 * weekOffset);
      const checkDate = new Date(date);
      checkDate.setDate(checkDate.getDate() + offset);
      checkDate.setDate(getFirstDayOfWeek(checkDate));
      return checkDate.toISOString().split('T')[0] !== selectedWeekFirstDay.toISOString().split('T')[0];
    },
    [firstDayOfCurrentWeek, weekOffset]
  );

  const allowFetchDiary = !isRestricted && !!firstDayOfCurrentWeek && !!lastDayOfCurrentWeek && !invalidateOnly;

  const { data: fetchAction } = useQuery<ActionReturnType>(
    ['userCalendarEvents', firstDayOfCurrentWeek, lastDayOfCurrentWeek, weekOffset],
    () => fetchData(firstDayOfCurrentWeek, lastDayOfCurrentWeek, weekOffset) as Promise<ActionReturnType>,
    {
      enabled: allowFetchDiary
    }
  );

  useEffect(() => {
    const startDate = new Date();
    const endDate = new Date();

    startDate.setDate(getFirstDayOfWeek(startDate));
    setFirstDayOfCurrentWeek(new Date(startDate));

    endDate.setDate(startDate.getDate() + 6);

    setLastDayOfCurrentWeek(endDate);
  }, []);

  useEffect(() => {
    if (fetchAction && fetchAction.type === actionTypes.USER_DIARY_SUCCESS) {
      const { slot_duration, appointments } = getAppointmentsAndDurationFromPayload(fetchAction?.payload);
      setSlotDuration(slot_duration);
      const appointmentEventList: AppointmentEvent[] = appointments?.map((item, index) => ({
        id: index,
        patient: item.patient,
        appointment_type: item.appointment_type,
        condition: item.condition,
        lucy_id: item.lucy_id,
        start: new Date(item.start),
        end: new Date(item.end),
        duration: item.slot_duration,
        completed: item.appointment_completed,
        call_type_code: item.call_type_code,
        appointment_id: item.appointment_id,
        entry_type: item.entry_type
      }));
      setEventList(appointmentEventList);
    }
  }, [fetchAction, weekOffset]);

  const invalidateCalendarEvents = useCallback(() => {
    queryClient
      .invalidateQueries({
        queryKey: ['userCalendarEvents', firstDayOfCurrentWeek, lastDayOfCurrentWeek, weekOffset]
      })
      .then();
  }, [firstDayOfCurrentWeek, lastDayOfCurrentWeek, queryClient, weekOffset]);

  return {
    eventList,
    invalidateCalendarEvents,
    slotDuration,
    weekOffset,
    setWeekOffset,
    isNextDateOutOfRange
  };
}
