import React from 'react';
import { Auth } from 'aws-amplify';
import getConfig from 'next/config';
import { userLogoutResponse, userRole } from 'store/actions/userReducers';
import { infoModal } from 'vl-common/src/components/Modals';
import { GenericResponse } from 'store/actions/action';
import { showLogoutAlert } from 'vl-common/src/services/service.api';

interface BackendFetchOptions extends RequestInit {
  dispatch?: any;
  unauthenticated?: boolean;
  disabledBackoff?: boolean;
  currentBackoff?: number;
}

async function warnCouldNotAccessBackend() {
  infoModal({
    title: 'Cannot Access',
    content: (
      <div>
        <p>Could not access the VirtualLucy servers.</p>
        <br />
        Please try again later.
        <br />
        <p>If the problem persists, try connecting using a different network or contact support.</p>
      </div>
    ),
    onOk: () => {
      return Promise.resolve();
    }
  });
}

let policiesWarned = false;

const redirectAcceptedPolicies = () => {
  if (policiesWarned || window.location.pathname === '/policy') return;
  window.location.pathname = '/policy';
  policiesWarned = true;
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export async function backendFetch<T>(input: RequestInfo, init?: BackendFetchOptions): Promise<GenericResponse<T>> {
  if (process.env.NODE_ENV === 'test') {
    throw Error('Tests should not be making backend requests');
  }

  if (!init?.unauthenticated) {
    await Auth.currentSession();
  }

  // @ts-expect-error
  const state = globalThis.GlobalStore?.getState?.() || { auth: {} };

  // this needs to be checked first in order to stop corrupt requests being made. See VL2-2174
  if (state.auth.hasSignedOut) {
    throw Error('Signed Out');
  }

  const headers = new Headers(init?.headers);
  headers.append('x-api-key', window.location.href);

  // fetch will only ever throw an exception if the request cannot be completed:
  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  const response: GenericResponse<T> = await fetch(input, { ...init, headers }).catch((err) => {
    console.error('Fetch threw an exception', err);

    // very occasionally fetch seems to throw if the requesting component is changing state at the same time.
    // try to fetch a second time after a delay and warn the user if that fails as well.
    return delay(300)
      .then(() => fetch(input, init))
      .catch((err) => {
        warnCouldNotAccessBackend();
        throw err;
      });
  });

  // unauthorized, Cognito has decided I'm not good anymore. Game over, login again.
  if (response.status === 401) {
    if (init?.dispatch) {
      const { dispatch } = init;

      // clear enough stuff out of redux to stop the login page trying to redirect to the dashboard
      dispatch(userRole(null));
      dispatch(userLogoutResponse());
      sessionStorage.clear();
    }

    console.error('401 unauthorized, redirecting to /login from backendFetch()', window.location.href);
    window.location.pathname = '/login';
    showLogoutAlert(`Unauthorised to access: ${input}. Please contact support with a screenshot of this page.`);
  }

  if (response.status === 429) {
    const { publicRuntimeConfig } = getConfig();
    const { DISABLED_EXPONENTIAL_BACKOFF } = publicRuntimeConfig;

    if (!DISABLED_EXPONENTIAL_BACKOFF) {
      const currentBackoff = init?.currentBackoff ?? 0;
      const backoff = 2 * (currentBackoff || 100);

      console.log('Throttled, backing off', currentBackoff, 'ms');
      await delay(backoff);
      return backendFetch(input, { ...init, currentBackoff: backoff });
    }
  }

  if (response.status === 420) {
    console.warn('Policies have not been accepted, redirecting to the /policy page');
    redirectAcceptedPolicies();
  }

  return response;
}

export default backendFetch;
